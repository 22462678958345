import { Injectable } from '@angular/core';
import { Observable, Subject } from 'rxjs';
@Injectable({
    providedIn: 'root'
})
export class CommonService {
    private urlSubject: Subject<string> = new Subject<string>(); // Subject to emit URL changes
    CrrentUrl:string = '';
    constructor() { }

    // Observable for components to subscribe to
    get url$(): Observable<string> {
        return this.urlSubject.asObservable();
    }

    // Method to update the URL
    updateUrl(url: string): void {
        // this.urlSubject.next(url);
        this.CrrentUrl = url;
    }
}
