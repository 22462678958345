import { Component, OnInit } from '@angular/core';
import { AppService } from 'src/app/_service/app.service';
import { NavigationEnd, Router } from '@angular/router';
import { NgxSpinnerService } from 'ngx-spinner';
import { CommonService } from 'src/app/_service/common.service';
import { last } from 'rxjs/operators';

@Component({
  selector: 'app-header',
  templateUrl: './header.component.html',
  styleUrls: ['./header.component.scss']
})
export class HeaderComponent implements OnInit {
  user: any = {};
  userProfile: any = {};
  moduleList: any[] = [];
  IsAdmin:boolean = false;
  subscription: any;
  isVisitor:boolean = false;
  IsGuest:boolean = false;

  constructor(
    private appService: AppService,
    private router: Router,
    private spinner: NgxSpinnerService,
    private common:CommonService
  ) {

     setTimeout(() => {
      console.log('current url',this.common.CrrentUrl);
      let currentUrl = this.common.CrrentUrl;
      this.IsGuest = ['/delete-user','/privacy-policy'].includes(currentUrl)
      let ModuleUrl = currentUrl.split('/')[1]
      this.IsAdmin = ['vertexadmin'].includes(ModuleUrl);
      console.log('this.IsAdmin: ', this.IsAdmin);
      this.isVisitor = currentUrl.includes('visitor');
      console.log('this.isVisitor: ', this.isVisitor);
     }, 0);

    // this.subscription = this.common.url$.subscribe((url) => {
    //   alert();
    //   console.log('url: ', url);
    //   let currentUrl = url;
    //   let endPoint = currentUrl.split('/').pop();
    //   let ModuleUrl = (url || '').split('/')[1] // Capture the final URL after redirects
    //   this.IsAdmin = ['vertexadmin'].includes(endPoint);
    //   this.isVisitor = currentUrl.includes('visitor');
    //   console.log('this.isVisitor: ', this.isVisitor);
   
    // });

    this.user = JSON.parse(localStorage.getItem('office_q_uid'));
   }

  ngOnInit() {
    this.userList();
    this.getModule();
  }

  userList() {
    this.appService.postService({ Id: this.user.UserId }, 'UserGetById')
      .subscribe(response => {
        this.userProfile = response.body.Result;
      });
  }

  getModule = () => {
    this.spinner.show();
    this.appService.postService({}, 'ModuleList')
      .subscribe(response => {
        if (response.body.Success) {
          this.moduleList = response.body.Result.filter(x => x.Id === 1 || x.Id === 2 || x.Id === 4);
          console.log(this.moduleList )
          this.spinner.hide();
        }
      });
  }

  openMasters(){
    this.router.navigate(['/app/dashboard/config-master'])
  }

  navigateToPage(url){
    this.router.navigateByUrl(url)
  }

  userLogout(){
    if(this.isVisitor){
      this.router.navigateByUrl('app/dashboard/visitor/visitor-login');
    }

    else{
      this.router.navigateByUrl('user/login');
    }
    
  }

  adminLogout(){
  this.router.navigateByUrl('vertexadmin/login');
  }

}
