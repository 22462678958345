import { Component, OnInit } from '@angular/core';
import { NavigationEnd, Router } from '@angular/router';
import { CommonService } from './_service/common.service';

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss']
})
export class AppComponent implements OnInit {
  IsNotLogin:boolean = false;
  urlEndpoint: string = '';
  constructor(private router:Router, private common:CommonService){

    this.router.events.subscribe((event) => {
      if (event instanceof NavigationEnd) {
        let currentUrl:string = event.urlAfterRedirects;
        this.urlEndpoint = (currentUrl || '').split('/').pop(); // Capture the final URL after redirects
        this.IsNotLogin =  !['login','visitor-login'].includes(this.urlEndpoint);
        this.common.updateUrl(currentUrl);
      }
    });
  }
  title = 'officeQueue';  

  ngOnInit(): void {
    
  }
}
