import { Component, Inject, OnInit } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA, MatDialog } from '@angular/material/dialog';
import { FormBuilder, FormGroup, Validators, AbstractControl, ValidationErrors, ValidatorFn, FormControl } from '@angular/forms';
import { Router } from '@angular/router';
import { AppService } from 'src/app/_service/app.service';
import { UserModalComponent } from 'src/@common/user-modal/user-modal.component';
import { CommonService } from 'src/app/_service/common.service';

@Component({
  selector: 'app-dialogeboxes',
  templateUrl: './dialogeboxes.component.html',
  styleUrls: ['./dialogeboxes.component.scss']
})
export class DialogeboxesComponent implements OnInit {
  dialogData: any;
  userDialogRef: MatDialogRef<UserModalComponent>;
  object:any = {
    AccessInfo:{},
  }
  userData:any = {
    Department:{
      DepartmentId:0,
      DepartmentName:"",
    },
    Designation:{
      DesignationId:0,
      DesignationName:"",
    },
    Location:{},
    ActiveStatus:true,
  };
  imageSrc:any;
  showUploadPhoto = true;
  department:any = {
    AccessInfo:{},
    ActiveStatus:true,
  };
  designation:any = {
    AccessInfo:{},
    ActiveStatus:true,
  };
  departmentList:any[] = [];
  designationList:any[] = [];

  constructor(
    public dialog: MatDialog,
    public dialogRef: MatDialogRef<DialogeboxesComponent>,
    @Inject(MAT_DIALOG_DATA) public data: any,
    private router: Router,
    public formBuilder: FormBuilder,
    private appService: AppService,
    public common:CommonService
  )
  {
    const usrdata = JSON.parse(localStorage.getItem('office_q_uid'));
    this.object.AccessInfo.UserId = usrdata.UserId;
    this.object.AccessInfo.UserLocationId = usrdata.UserLocationId;
    this.object.AccessInfo.CompanyId = usrdata.CompanyId;
    this.department.AccessInfo.UserId = usrdata.UserId;
    this.department.AccessInfo.UserLocationId = usrdata.UserLocationId;
    this.department.AccessInfo.CompanyId = usrdata.CompanyId;
    this.designation.AccessInfo.UserId = usrdata.UserId;
    this.designation.AccessInfo.UserLocationId = usrdata.UserLocationId;
    this.designation.AccessInfo.CompanyId = usrdata.CompanyId;
    console.log("data",data);
    this.dialogData = data;

}

    // Method to close the dialog
    onClose(): void {
      this.dialogRef.close(); // Close the dialog without any result
    }

    submit() {
      console.log(this.userData);
      this.userData.Location.LocationId = this.object.AccessInfo.UserLocationId;
      this.userData.CompanyId = this.object.AccessInfo.CompanyId;
      this.appService.postService({...this.userData}, 'UserSave')
      .subscribe(response => {
        console.log(response)
      });
      // Perform any actions here (e.g., save the new project)

      this.dialogRef.close(true); // Pass `true` to indicate success
    }


  ngOnInit() {
    this.getDepartmentDropDownList();
    this.getDesignationDropdownList();
  }

  getDepartmentDropDownList(){
    this.appService.postService({...this.object}, 'GetDepartmentDropdownList')
    .subscribe(response => {
      console.log(response)
      this.departmentList = response.body.Result;
      const a = this.departmentList.sort((a, b) => {
        if (a.DepartmentName < b.DepartmentName) { return -1; }
        if (a.DepartmentName > b.DepartmentName) { return 1; }
        return 0;
      })
    });
  }

  getDesignationDropdownList(){
    this.appService.postService({...this.object}, 'GetDesignationDropdownList')
    .subscribe(response => {
      console.log(response)
      this.designationList = response.body.Result;
      const a = this.designationList.sort((a, b) => {
        if (a.DesignationName < b.DesignationName) { return -1; }
        if (a.DesignationName > b.DesignationName) { return 1; }
        return 0;
      })
    });
  }
  adddepartmentpopup(){
    const dialogRef = this.dialog.open(DialogeboxesComponent, {
     // width: '800px',
      //height: 'auto',
     // panelClass: "modal-sm",
      autoFocus: true,
      restoreFocus: false,
      disableClose: false,
      data: { action: 'AddDepartmentPopupModel', title: 'User Registration', data: null },
    });
    dialogRef.afterClosed().subscribe((result: any) => {
      if (result === true) {
      }
    });
  }

  addNewDepartment(){
    this.appService.postService({...this.department}, 'DepartmentSave')
    .subscribe(response => {
      console.log(response)
      this.getDepartmentDropDownList();
    });
  }

  deleteDepartment(item:any){
    this.department.Id = item.DepartmentId;
    this.appService.postService({...this.department}, 'DepartmentDelete')
    .subscribe(response => {
      console.log(response)
      this.getDepartmentDropDownList();
    });

  }

  addDesignationpopup(){
    const dialogRef = this.dialog.open(DialogeboxesComponent, {
     // width: '800px',
      //height: 'auto',
     // panelClass: "modal-sm",
      autoFocus: true,
      restoreFocus: false,
      disableClose: false,
      data: { action: 'AddDesignationPopupModel', title: 'User Registration', data: null },
    });
    dialogRef.afterClosed().subscribe((result: any) => {
      if (result === true) {
      }
    });
  }

  addNewDesignation(){
    this.appService.postService({...this.designation}, 'DesignationSave')
    .subscribe(response => {
      console.log(response)
      this.getDesignationDropdownList();
    });
  }

  deleteDesignation(item:any){
    this.designation.Id = item.DesignationId;
    this.appService.postService({...this.designation}, 'DesignationDelete')
    .subscribe(response => {
      console.log(response)
      this.getDesignationDropdownList();
    });
  }

  doApprove(obj:any){
    // this.appService.postService({...obj}, 'TaskDelete')
    // .subscribe(response => {
    //   console.log(response)
    //   //this.getTaskDashList();
    // });
  }

  openToModal(){
    this.userDialogRef = this.dialog.open(UserModalComponent, {
      autoFocus: false,
      panelClass: 'user_model',
      data: { userData: this.dialogData.data.AssignTo }
    });
    this.userDialogRef.afterClosed()
      .subscribe(result => {
        if (!result) {
          return;
        } else {
          this.dialogData.data.AssignTo = result;
        }
      });
  }

  updateTaskStatus(status:number){
    this.dialogData.data.Status=status;
    this.appService.postService({...this.dialogData.data}, 'TaskUpdate')
    .subscribe(response => {
      console.log(response)
      this.dialogRef.close();
      this.router.navigate(['/app/dashboard/task']);
    });

  }

  taskiassignto(usr:any){
    const dialogRef = this.dialog.open(DialogeboxesComponent, {
      width: '800px',
      height: 'auto',
      panelClass: "modal-sm",
      autoFocus: true,
      restoreFocus: false,
      disableClose: false,
      data: { action: 'TaskAssignto', title: 'User Registration', data: usr },
    });
    dialogRef.afterClosed().subscribe((result: any) => {
      if (result === true) {
      }
    });
}

toggleUploadMode() {
  this.showUploadPhoto = !this.showUploadPhoto;
}

onFileSelected(event:any){
  const file = (event.target as HTMLInputElement).files[0];
		if (file) {
			const reader = new FileReader();
			reader.onload = () => {
				this.imageSrc = reader.result as string; // Preview the uploaded image
				this.userData.PhotoName = file.name; // Store the file name
				this.userData.PhotoPath = reader.result as string;
			};
			reader.readAsDataURL(file);
		}
}

}
