import { Component, OnInit } from '@angular/core';
import { NavigationEnd, NavigationError, NavigationStart, Router } from '@angular/router';
import { CommonService } from './_service/common.service';

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss']
})
export class AppComponent implements OnInit {
  IsNotLogin:boolean = false;
  urlEndpoint: string = '';
  constructor(private router:Router, private common:CommonService){

    this.router.events.subscribe((event) => {
      if (event instanceof NavigationEnd) {
        console.log('router',this.router);
        let currentUrl:string = event.urlAfterRedirects;
        this.urlEndpoint = (currentUrl || '').split('/').pop(); // Capture the final URL after redirects
        this.IsNotLogin =  !['login','visitor-login'].includes(this.urlEndpoint);
        this.common.updateUrl(currentUrl);
      }
    });
    // this.router.events.subscribe(event => {
    //   if (event instanceof NavigationEnd) {
    //     console.log('Navigated to:', event.url);
    //   }
    //   if (event instanceof NavigationStart) {
    //     console.log('Navigation started:', event.url);
    //   }
    //   if (event instanceof NavigationError) {
    //     console.error('Navigation error:', event.error);
    //   }
    // });
  }


  title = 'officeQueue';

  ngOnInit(): void {

  }
}
