import {Component,ElementRef,ViewChild,	AfterViewInit,Renderer2} from '@angular/core';
import {Location} from '@angular/common';
import {MatDialogRef,MatDialog} from '@angular/material/dialog';
import { AppService } from 'src/app/_service/app.service';
import { NgxSpinnerService } from 'ngx-spinner';
import { Router, ActivatedRoute } from '@angular/router';

@Component({
	selector: 'app-visitorrequest',
	templateUrl: './visitorrequest.component.html',
	styleUrls: ['./visitorrequest.component.scss']
})
export class VisitorrequestComponent implements AfterViewInit {
		isActive = '';
		showUploadPhoto = true;
		cameraImageUpload: boolean = false;
		cameraImage: string | ArrayBuffer = null;
		imageSrc: string | ArrayBuffer | null = null;
		stream: MediaStream | null = null;
		captures: string[] = []; // Array to store captured images
		visitorEntry:any = {
			ImageFileName: '',
			ImageFile:"",
			AccessInfo:{},
			VistorPriority:{
				VistorPriorityId:1,
				VisitorPriorityColor:"#fff",
			},
		}; // Your model
	
		// ViewChild references for video and canvas elements
		@ViewChild('video', {
			static: false
		}) public video: ElementRef < HTMLVideoElement > ;
		@ViewChild('canvas', {
			static: false
		}) public canvas: ElementRef < HTMLCanvasElement > ;
	
		vsObject:any = {
			AccessInfo: {},
		}
		psObject:any = {
			AccessInfo: {},
			VidhanSabha:{}
		}
		gpObject:any = {
			AccessInfo: {},
			PachaytSamiti:{}
		}
		gvObject:any = {
			AccessInfo: {},
			GramPanchayat:{}
		}
		city:any = {
			State:{},
		}
		vsDropdownList:any[] = [];
		psDropdownList:any[] = [];
		gpDropdownList:any[] = [];
		gvDropdownList:any[] = [];
		stateArr:any[] = [];
		cityArr:any[] = [];
	
		constructor(
			public dialog: MatDialog,
			private locationn: Location,
			private renderer: Renderer2,private appService: AppService,
			private router: Router,
			private spinner: NgxSpinnerService,
			private route: ActivatedRoute,
		) {
		const data = JSON.parse(localStorage.getItem('office_q_uid'));
		  this.vsObject.AccessInfo.UserId = data.UserId;
		  this.vsObject.AccessInfo.UserLocationId = data.UserLocationId;
		  this.vsObject.AccessInfo.CompanyId = data.CompanyId;
		  this.psObject.AccessInfo.UserId = data.UserId;
		  this.psObject.AccessInfo.UserLocationId = data.UserLocationId;
		  this.psObject.AccessInfo.CompanyId = data.CompanyId;
		  this.gpObject.AccessInfo.UserId = data.UserId;
		  this.gpObject.AccessInfo.UserLocationId = data.UserLocationId;
		  this.gpObject.AccessInfo.CompanyId = data.CompanyId;
		  this.gvObject.AccessInfo.UserId = data.UserId;
		  this.gvObject.AccessInfo.UserLocationId = data.UserLocationId;
		  this.gvObject.AccessInfo.CompanyId = data.CompanyId;
		  this.visitorEntry.AccessInfo.UserId = data.UserId;
		  this.visitorEntry.AccessInfo.UserLocationId = data.UserLocationId;
		  this.visitorEntry.AccessInfo.CompanyId = data.CompanyId;
		}
	
		ngOnInit() {
			// Additional initialization logic can go here
			this.isActive = 'Constituency';
			if(document.getElementById('div_UploadPhoto')){
				this.renderer.setStyle(document.getElementById('div_UploadPhoto'), 'display', 'block');
				this.playcamera();
			}
	
			if(document.getElementById('div_CapturePhoto')){
				this.renderer.setStyle(document.getElementById('div_CapturePhoto'), 'display', 'none');
				this.playcamera();
			}
			this.getVidhanSabhaDropdownList();
			this.getStateDropdownList();
			this.getVisitor();
			// this.getPanchayatSamitiDropdownList();
			// this.getGramPanchayatDropdownList();
			// this.getGramVillageDropdownList();
		}
	
		//Adding new Visitor
		addVisitor(){
			if(this.isActive=="Constituency")
				this.visitorEntry.ConstituencyType = "Constituency";
			else
				this.visitorEntry.ConstituencyType = "Other";
			console.log({ ...this.visitorEntry})
			this.appService.post2Service({ ...this.visitorEntry}, 'Visitor/VisitorSave')
				.subscribe(response => {
				console.log(response)
				if (response.body.Success) {
					//this.router.navigate(['/app/dashboard/visitor']);
					//this.getTaskList();
				}
			});
		}
	
		getVisitor(){
			
			this.route.queryParams.subscribe(params => {
				if (params.id) {
				  this.spinner.show();
				  this.visitorEntry.Id = params.id;
				  this.appService.post2Service({...this.visitorEntry}, 'Visitor/getVisitor')
					.subscribe(response => {
					  this.visitorEntry = response.body.Result;
					  if(this.visitorEntry.ConstituencyType=="Constituency"){
						this.isActive="Constituency"
						this.psObject.VidhanSabha = this.visitorEntry.VidhanSabha;
						this.getPanchayatSamitiDropdownList();
						this.gpObject.PachaytSamiti = this.visitorEntry.PanchayatSamiti;
						this.getGramPanchayatDropdownList();
						this.gvObject.GramPanchayat = this.visitorEntry.GramPanchayat;
						this.getGramVillageDropdownList();
					  } else{
						this.isActive="Other";
						this.city.State.StateId = this.visitorEntry.State.StateId;
						this.getCityDropdownList();
					  }
	
					  
					  console.log(response)
					  })
					  //this.task.StartTime = this.dateTimeFormat(this.task.StartTime);
					  this.spinner.hide();
					}
			  });
		}
		//VidhanSabha Dropdown
		getVidhanSabhaDropdownList(){
			this.appService.post2Service({...this.vsObject}, 'VidhanSabha/GetVidhanSabhaDropdownList')
				  .subscribe(response => {
					this.vsDropdownList = response.body.Result;
				  });
		  }
		//PanchayatSamity Dopdown
		getPanchayatSamitiDropdownList(){
			this.appService.post2Service({...this.psObject}, 'PanchayatSamiti/GetPanchayatSamitiDropdownList')
				  .subscribe(response => {
					this.psDropdownList = response.body.Result;
				  });
		  }
	
		//Gram Panchayat Dropdown
		getGramPanchayatDropdownList(){
			this.appService.post2Service({...this.gpObject}, 'GramPanchayat/GetGramPanchayatDropdownList')
				.subscribe(response => {
					this.gpDropdownList = response.body.Result;
				});
		}
	
	  //GramVillage Dropdown
	  getGramVillageDropdownList(){
		this.appService.post2Service({...this.gvObject}, 'GramVillage/GetGramVillageDropdownList')
			  .subscribe(response => {
				this.gvDropdownList = response.body.Result;
			  });
	  }
	
	  getStateDropdownList(){
		this.appService.post2Service({}, 'Visitor/getStates')
			  .subscribe(response => {
				this.stateArr = response.body.Result;
			  });
	  }
	  getCityDropdownList(){
		this.appService.postService({...this.city}, 'CityDropDownList')
			  .subscribe(response => {
				this.cityArr = response.body.Result.CityList;
			  });
	  }
	  selectedVidhanSabha(item:any){
		this.psObject.VidhanSabha.vs_id=item.value.vs_id;
		this.getPanchayatSamitiDropdownList();
	  }
	  selectedPanchayatSamity(item:any){
		this.gpObject.PachaytSamiti.ps_id=item.value.ps_id;
		this.getGramPanchayatDropdownList();
	  }
	  selectedGramPanchayat(item:any){
		this.gvObject.GramPanchayat.gp_id=item.value.gp_id;
		this.getGramVillageDropdownList();
	  }
	  selectState(item:any){
		this.city.State.StateId=item.value.StateId;
		this.getCityDropdownList();
	  }
		playcamera() {
			this.captures = null;
			if (navigator.mediaDevices && navigator.mediaDevices.getUserMedia) {
				navigator.mediaDevices.getUserMedia({
					video: true
				}).then(stream => {
					this.stream = stream; // Save the stream to stop it later
					this.video.nativeElement.srcObject = stream;
					this.video.nativeElement.play();
					
				}).catch(error => {
					console.error('Error accessing the camera:', error);
				});
			}	
		}
		
		stopCamera() {
			if (this.stream) {
				// Stop all tracks to ensure the camera turns off
				this.stream.getTracks().forEach(track => {
					track.stop();
				});
				this.video.nativeElement.srcObject = null; // Release the video source
				this.stream = null; // Clear the stream reference
			}
		}
	
	
		ngAfterViewInit() {
			// this.playcamera()
			// if (navigator.mediaDevices && navigator.mediaDevices.getUserMedia) {
			// 	navigator.mediaDevices.getUserMedia({
			// 		video: true
			// 	}).then(stream => {
			// 		//  this.video.nativeElement.src = window.URL.createObjectURL(stream);
			// 		this.video.nativeElement.srcObject = stream;
			// 		this.video.nativeElement.play();
			// 	});
			// }
		}
	
		showHideUploadImage() {
			this.renderer.setStyle(document.getElementById('div_UploadPhoto'), 'display', 'none');
			this.renderer.setStyle(document.getElementById('div_CapturePhoto'), 'display', 'block');
		}
	
		HideUploadImage() {
			this.renderer.setStyle(document.getElementById('div_UploadPhoto'), 'display', 'block');
			this.renderer.setStyle(document.getElementById('div_CapturePhoto'), 'display', 'none');
	
		}
	
		toggleUploadMode() {
			this.showUploadPhoto = !this.showUploadPhoto;
		}
	
		toggleCameraUpload(enable: boolean = true) {
			this.cameraImageUpload = enable;
			this.showUploadPhoto = !enable; // Hide upload photo when in camera mode
		}
	
	
		capture() {
			this.showHideUploadImage();
			const context = this.canvas.nativeElement.getContext('2d').drawImage(this.video.nativeElement, 0, 0, 200, 200);
	
			this.captures = [];
			this.visitorEntry.ImageFileName = this.canvas.nativeElement.toDataURL('image/jpg');
			this.captures.push(this.canvas.nativeElement.toDataURL('image/jpg'));
			this.visitorEntry.ImageFileName = this.visitorEntry.VisitorName + '.png'; //this.makeid(this.captures.length) + '.png';
			this.imageSrc = this.captures.toString();
			this.visitorEntry.ImageFile = this.captures.toString();
			
		}
		makeid(length) {
			let result = '';
			const characters = 'ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789';
			const charactersLength = characters.length;
			for (let i = 0; i < length; i++) {
				result += characters.charAt(Math.floor(Math.random() * charactersLength));
			}
			return result;
		}
		Complete(){
			this.stopCamera();
			this.HideUploadImage();
		}
		onFileChange(event: Event) {
			const input = event.target as HTMLInputElement;
			if (input.files && input.files.length) {
				const file = input.files[0];
				const reader = new FileReader();
				reader.onload = (e: ProgressEvent) => {
					this.cameraImage = (e.target as FileReader).result as string;
				};
				reader.readAsDataURL(file);
			}
		}
	
		goBack(): void {
			this.locationn.back();
		}
	
	compareFn(a: any, b: any) {
	  if (b) {
		const d = a[Object.keys(a)[0]];
		const e = b[Object.keys(b)[0]];
		return d === e;
	  }
	}
		onFileSelected(event: Event) {
			const file = (event.target as HTMLInputElement).files[0];
			if (file) {
				const reader = new FileReader();
				reader.onload = () => {
					this.imageSrc = reader.result as string; // Preview the uploaded image
					this.visitorEntry.ImageFileName = file.name; // Store the file name
					this.visitorEntry.ImageFile = reader.result as string; 
				};
				reader.readAsDataURL(file);
			}
		}
	}
	