import { Component, Inject, OnInit } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA, MatDialog } from '@angular/material/dialog';
import { FormBuilder, FormGroup, Validators, AbstractControl, ValidationErrors, ValidatorFn, FormControl } from '@angular/forms';
import { Router } from '@angular/router';
import { AppService } from 'src/app/_service/app.service';
import { UserModalComponent } from 'src/@common/user-modal/user-modal.component';

@Component({
  selector: 'app-dialogeboxes',
  templateUrl: './dialogeboxes.component.html',
  styleUrls: ['./dialogeboxes.component.scss']
})
export class DialogeboxesComponent implements OnInit {
  dialogData: any;
  userDialogRef: MatDialogRef<UserModalComponent>;
  task:any = {
    AccessInfo:{},
  }
  
  constructor(
    public dialog: MatDialog,
    public dialogRef: MatDialogRef<DialogeboxesComponent>,
    @Inject(MAT_DIALOG_DATA) public data: any,
    private router: Router,
    public formBuilder: FormBuilder,
    private appService: AppService,
  ) 
  { 
    const usrdata = JSON.parse(localStorage.getItem('office_q_uid'));
    this.task.AccessInfo.UserId = usrdata.UserId;
    this.task.AccessInfo.UserLocationId = usrdata.UserLocationId;
    this.task.AccessInfo.CompanyId = usrdata.CompanyId;
    console.log("data",data);
    this.dialogData = data;   
}
   
    // Method to close the dialog
    onClose(): void {
      this.dialogRef.close(); // Close the dialog without any result
    }
  
    submit() {
      // Perform any actions here (e.g., save the new project)
      this.dialogRef.close(true); // Pass `true` to indicate success
    }
  

  ngOnInit() {
  }
  adddepartmentpopup(){  
    const dialogRef = this.dialog.open(DialogeboxesComponent, {
     // width: '800px',
      //height: 'auto',
     // panelClass: "modal-sm",
      autoFocus: true,
      restoreFocus: false,
      disableClose: false,
      data: { action: 'AddDepartmentPopupModel', title: 'User Registration', data: null },
    });
    dialogRef.afterClosed().subscribe((result: any) => {
      if (result === true) {
      }
    });
  }

  addDesignationpopup(){  
    const dialogRef = this.dialog.open(DialogeboxesComponent, {
     // width: '800px',
      //height: 'auto',
     // panelClass: "modal-sm",
      autoFocus: true,
      restoreFocus: false,
      disableClose: false,
      data: { action: 'AddDesignationPopupModel', title: 'User Registration', data: null },
    });
    dialogRef.afterClosed().subscribe((result: any) => {
      if (result === true) {
      }
    });
  }

  doApprove(obj:any){
    // this.appService.postService({...obj}, 'TaskDelete')
    // .subscribe(response => {
    //   console.log(response)
    //   //this.getTaskDashList();
    // });
  }

  openToModal(){
    this.userDialogRef = this.dialog.open(UserModalComponent, {
      autoFocus: false,
      panelClass: 'user_model',
      data: { userData: this.dialogData.data.AssignTo }
    });
    this.userDialogRef.afterClosed()
      .subscribe(result => {
        if (!result) {
          return;
        } else {
          this.dialogData.data.AssignTo = result;
        }
      });
  }

  updateTaskStatus(status:number){
    this.dialogData.data.Status=status;
    this.appService.postService({...this.dialogData.data}, 'TaskUpdate')
    .subscribe(response => {
      console.log(response)
      this.dialogRef.close();
      this.router.navigate(['/app/dashboard/task']);
    });
    
  }

  taskiassignto(usr:any){  
    const dialogRef = this.dialog.open(DialogeboxesComponent, {
      width: '800px',
      height: 'auto',
      panelClass: "modal-sm",
      autoFocus: true,
      restoreFocus: false,
      disableClose: false,
      data: { action: 'TaskAssignto', title: 'User Registration', data: usr },
    });
    dialogRef.afterClosed().subscribe((result: any) => {
      if (result === true) {
      }
    });
}
}
