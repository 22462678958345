import { BrowserModule } from '@angular/platform-browser';
import { NgModule } from '@angular/core';
//import { DatePipe } from '@angular/common';
import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { SharedModule } from 'src/@common/shared.module';
import { HttpClientModule } from '@angular/common/http';
import { NgxSpinnerModule } from 'ngx-spinner';
import { MAT_DATE_LOCALE } from '@angular/material/core';
import { MatIconModule } from '@angular/material/icon';
import { VisitorloginComponent } from './visitorlogin/visitorlogin.component';
import { VisitorrequestComponent } from './visitorrequest/visitorrequest.component';
import { QrcodeComponent } from './qrcode/qrcode.component';
import { PrintpagesettingsComponent } from './printpagesettings/printpagesettings.component';
import { VertexloginComponent } from './vertexadmin/vertexlogin/vertexlogin.component';
import { PrivacyPolicyComponent } from './privacy-policy/privacy-policy.component';
import { DeleteUserComponent } from './delete-user/delete-user.component';
import { ProfileComponent } from './profile/profile.component';
import { HeaderComponent } from './configuration/header/header.component';
// import { NativeDateAdapter } from '@angular/material/core';
// import { MatDatepickerModule } from '@angular/material/datepicker';
// import { MatFormFieldModule } from '@angular/material/form-field';
// import { ReactiveFormsModule } from '@angular/forms';
// import { MatInputModule } from '@angular/material/input';
@NgModule({
  declarations: [
    AppComponent,
    VisitorloginComponent,
    VisitorrequestComponent,
    QrcodeComponent,
    PrintpagesettingsComponent,
    PrivacyPolicyComponent,
    DeleteUserComponent,
    ProfileComponent,
    HeaderComponent
  ],
  imports: [
    BrowserModule,
    AppRoutingModule,
    BrowserAnimationsModule,
    SharedModule,
    HttpClientModule,
    NgxSpinnerModule,
    MatIconModule,
  ],
  exports:[
    HeaderComponent
  ],
  providers: [{ provide: MAT_DATE_LOCALE, useValue: 'en-GB' }],
  bootstrap: [AppComponent]
})
export class AppModule { }
