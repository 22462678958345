
import { Component, OnInit } from '@angular/core';
import { MatDialogRef, MatDialog } from '@angular/material';
import { Location } from '@angular/common';
import { AppService } from 'src/app/_service/app.service';
import { DomSanitizer } from '@angular/platform-browser';

@Component({
  selector: 'app-qrcode',
  templateUrl: './qrcode.component.html',
  styleUrls: ['./qrcode.component.scss']
})
export class QrcodeComponent implements OnInit {
  isActive = 'listView';
  dialogRef: any;
  showSearch: boolean;
  advanceSearch:boolean;
  showDuration: boolean = true;
  qrCodeData:any = {
    AccessInfo: {},
  };
  qrData:any[] = [];
  qrImage:any[] = [];
  qrOriginalImage:any[] = [];


  constructor(public dialog: MatDialog,
    private locationn: Location,private appService: AppService,private _sanitizer: DomSanitizer
    ) {
      const data = JSON.parse(localStorage.getItem('office_q_uid'));
      this.qrCodeData.AccessInfo.UserId = data.UserId;
      this.qrCodeData.AccessInfo.UserLocationId = data.UserLocationId;
      this.qrCodeData.AccessInfo.CompanyId = data.CompanyId;
    }

  ngOnInit() {
    this.getQRCode();
  }

  getQRCode(){
    this.appService.post2Service({...this.qrCodeData}, 'Locations/GetQRCode')
			  .subscribe(response => {
         // this.qrImage = response.body.Result;
          if(response.body.Result!=null){
          //  this.qrData = response.body.Result;
          this.qrOriginalImage = response.body.Result;
            this.qrImage = response.body.Result;
            this.qrImage.forEach(element => {
              element.ImageBase64 =  this._sanitizer.bypassSecurityTrustResourceUrl(element.ImageBase64)
            });
          }
			  });
  }
  
    goBack(): void{
      this.locationn.back();
    }
    downloadImage(item:any) {
      const link = document.createElement('a');
      const data = this.qrOriginalImage.find(x=> x.LocationId == item.LocationId);
      const base64: string[] = data.ImageBase64.toString().split(',')[1].split(" ");
      document.body.appendChild(link);
      const byteString = atob(base64[0]);
      const arrayBuffer = new ArrayBuffer(byteString.length);
      const int8Array = new Uint8Array(arrayBuffer);
      for (let i = 0; i < byteString.length; i++) {
        int8Array[i] = byteString.charCodeAt(i);
      }
      const imageBlob = new Blob([int8Array], { type: 'image/png' });
      const url = URL.createObjectURL(imageBlob);
        link.setAttribute("href", url);
        link.setAttribute("download", "QRCode_" + item.CompanyId + "_" + item.LocationId + ".png");
      link.click();
      document.body.removeChild(link);
    }

}
