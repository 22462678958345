import { Injectable } from '@angular/core';
import { MatDialog } from '@angular/material';
import { DomSanitizer, SafeResourceUrl } from '@angular/platform-browser';
import { Observable, Subject } from 'rxjs';
import { DialogeboxesComponent } from 'src/@common/dialogeboxes/dialogeboxes.component';
import { AppService } from './app.service';
import { map } from 'rxjs/operators';
@Injectable({
  providedIn: 'root'
})

export class CommonService {
  private urlSubject: Subject<string> = new Subject<string>(); // Subject to emit URL changes
  CrrentUrl: string = '';
  ComapnyData: any = {
    CompanyId: null,
    CompanyName: "",
    Address: "",
    City: "",
    Mobile: "",
    Email: "",
    CompanyLogo: "",
    ContactPerson:"",
    AssignedModules:""
  }
  generalpayload: any = {
    AccessInfo: {},
  }

  UserDetails:any={
    UserId:null,
    UserLocationId:null,
    UserName:null,
  }

  UserProfile: any = {
    name: '',
    phone: '',
    email: '',
    companyName: '',
    department: '',
    designation: '',
    assignLocation: '',
    assignModule: ''
  }
  constructor(public dialog: MatDialog,private sanitizer: DomSanitizer, private appService: AppService) {
    const data = JSON.parse(localStorage.getItem('office_q_uid'));

    if(data){
      this.generalpayload.AccessInfo.UserId = data.UserId;
      this.generalpayload.AccessInfo.UserLocationId = data.UserLocationId;
      this.generalpayload.AccessInfo.CompanyId = data.CompanyId;
    }

  }

  // Observable for components to subscribe to
  get url$(): Observable<string> {
    return this.urlSubject.asObservable();
  }

  // Method to update the URL
  updateUrl(url: string): void {
    // this.urlSubject.next(url);
    this.CrrentUrl = url;
    console.log('this.CrrentUrl: ', this.CrrentUrl);
  }
  openImageModal(imageSrc: any) {
    this.dialog.open(DialogeboxesComponent, {
      data: { imageSrc,IsPdf:false },
    });
}
openImageModalPdf(imageSrc: any) {
  this.dialog.open(DialogeboxesComponent, {
    data: { imageSrc,IsPdf:true },

  });
}
convertToLocal(date: string): string {
  if (!date) return date;

  const localDate = new Date(date);
  const year = localDate.getFullYear();
  const month = String(localDate.getMonth() + 1).padStart(2, '0');
  const day = String(localDate.getDate()).padStart(2, '0');

  // Send in YYYY-MM-DD format without time
  return `${year}-${month}-${day}`;
}
getPdfUrl(box: any): SafeResourceUrl {
  const pdfData = `data:application/pdf;base64,${box.ImageFile}`;
  // console.log('pdfData: ', pdfData);
  return this.sanitizer.bypassSecurityTrustResourceUrl(pdfData);
}
openPdfInNewTab(box: any): void {
  // Generate the PDF URL
  const pdfUrl = `data:application/pdf;base64,${box.ImageFile}`;

  // Open the PDF in a new tab
  const newTab = window.open();
  if (newTab) {
    newTab.document.write(`
      <html>
        <head>
          <title>PDF Preview</title>
        </head>
        <body style="margin:0; padding:0;">
          <iframe src="${pdfUrl}" width="100%" height="100%" style="border:none;"></iframe>
        </body>
      </html>
    `);
    newTab.document.close();
  } else {
    console.error('Failed to open a new tab. Please check pop-up blocker settings.');
  }
}
getLocationDropDownList(): Observable<any[]> {
  return this.appService.post2Service({ ...this.generalpayload }, 'Locations/GetLocationsDropdownList').pipe(
    map((response: any) => {
      const tmpArr = response.body.Result;
      // Sort the array alphabetically by LocationName
      const sortedArr = tmpArr.sort((a, b) => (a.LocationName < b.LocationName ? -1 : 1));
      // Add "All" option at the beginning of the array
      sortedArr.unshift({ LocationId: 0, LocationName: 'All' });
      return sortedArr;
    })
  );
}
}
