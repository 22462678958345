import { Component, OnInit } from '@angular/core';
import { AppService } from 'src/app/_service/app.service';
import { NavigationEnd, Router } from '@angular/router';
import { NgxSpinnerService } from 'ngx-spinner';
import { CommonService } from 'src/app/_service/common.service';
import { last } from 'rxjs/operators';

@Component({
  selector: 'app-header',
  templateUrl: './header.component.html',
  styleUrls: ['./header.component.scss']
})
export class HeaderComponent implements OnInit {
  user: any = {};
  userProfile: any = {};
  moduleList: any[] = [];
  IsAdmin:boolean = false;
  subscription: any;
  isVisitor:boolean = false;
  IsGuest:boolean = false;
  IsUser:boolean=false;
  CompanyDetails: any;
isPublicPage:boolean=false;
  constructor(
    private appService: AppService,
    private router: Router,
    private spinner: NgxSpinnerService,
    public common:CommonService
  ) {

     setTimeout(() => {
      console.log('current url',this.common.CrrentUrl);
      let currentUrl = this.common.CrrentUrl;
       this.isPublicPage = ['/privacy-policy', '/delete-user'].includes(currentUrl);
// this.isPublicPage=true;
      this.IsGuest = ['/delete-user','/privacy-policy'].includes(currentUrl)
      let ModuleUrl = currentUrl.split('/')[1]
      this.IsAdmin = ['vertexadmin'].includes(ModuleUrl);
      console.log('this.IsAdmin: ', this.IsAdmin);
      this.isVisitor = currentUrl.includes('visitor');
      console.log('this.isVisitor: ', this.isVisitor);
     }, 0);

    // this.subscription = this.common.url$.subscribe((url) => {
    //   alert();
    //   console.log('url: ', url);
    //   let currentUrl = url;
    //   let endPoint = currentUrl.split('/').pop();
    //   let ModuleUrl = (url || '').split('/')[1] // Capture the final URL after redirects
    //   this.IsAdmin = ['vertexadmin'].includes(endPoint);
    //   this.isVisitor = currentUrl.includes('visitor');
    //   console.log('this.isVisitor: ', this.isVisitor);

    // });

    this.user = JSON.parse(localStorage.getItem('office_q_uid'));
    this.common.UserDetails.UserId=this.user.UserId;
    this.common.UserDetails.UserLocatonId=this.user.UserLocationId;
    this.common.UserDetails.UserName=this.user.UserName;
    
   }

  async ngOnInit() {
    await this.userList();
    await this.GetCompanyGetById();
    await this.getModule();
  }

  userList() {
    this.appService.postService({ Id: this.user.UserId }, 'UserGetById')
      .subscribe(response => {
        this.userProfile = response.body.Result;
        this.common.UserProfile.name=this.userProfile.FirstName+this.userProfile.LastName;
        this.common.UserProfile.phone=this.userProfile.Phone;
        this.common.UserProfile.email=this.userProfile.EmailId;
        if(this.userProfile.Department){
          this.common.UserProfile.department=this.userProfile.Department.DepartmentName;

        }
        if (this.userProfile.Designation) {
          this.common.UserProfile.designation = this.userProfile.Designation.DesignationName;
        }
        if (this.userProfile.Location) {
          this.common.UserProfile.assignLocation = this.userProfile.Location.LocationName;
        }
        if(this.userProfile && this.userProfile.AssignedPermission && this.userProfile.AssignedPermission.length > 0){
          this.common.UserProfile.assignModule = this.userProfile.AssignedPermission.map(module => module.ModuleName).join(',') || '';
          
        }
        console.log('this.common.UserProfile: ', this.common.UserProfile);

      });
  }
  GetCompanyGetById(){
    const companyData = JSON.parse(localStorage.getItem('office_q_uid'));
    console.log('companyData: ', companyData);
    this.appService.post2Service({id: companyData.CompanyId}, 'Company/CompanyGetById')
    .subscribe(response => {
      this.CompanyDetails = response.body.Result;
      this.common.ComapnyData.CompanyId = this.CompanyDetails.CompanyId;
      this.common.ComapnyData.CompanyName = this.CompanyDetails.CompanyName;
      this.common.ComapnyData.Address = this.CompanyDetails.Address;
      this.common.ComapnyData.City = this.CompanyDetails.City;
      this.common.ComapnyData.Mobile = this.CompanyDetails.Mobile;
      this.common.ComapnyData.Email = this.CompanyDetails.Email;
      this.common.ComapnyData.CompanyLogo = this.CompanyDetails.CompanyLogo;
      this.common.ComapnyData.ContactPerson=this.CompanyDetails.ContactPerson;
      if(this.CompanyDetails && this.CompanyDetails.AssignedModules && this.CompanyDetails.AssignedModules.length > 0){
        this.common.ComapnyData.AssignedModules = this.CompanyDetails.AssignedModules.map(module => module.ModuleName).join(',') || '';

      }
      console.log('this.common.ComapnyData: ', this.common.ComapnyData);
      console.log('this.CompanyDetails: ', this.CompanyDetails);
    });
  }
  getModule = () => {
    this.spinner.show();
    this.appService.postService({}, 'ModuleList')
      .subscribe(response => {
        if (response.body.Success) {
          this.moduleList = response.body.Result.filter(x => x.Id === 1 || x.Id === 2 || x.Id === 4);
          console.log(this.moduleList )
          this.spinner.hide();
        }
      });
  }

  openMasters(){
    this.router.navigate(['/app/dashboard/config-master'])
  }

  navigateToPage(url){
    this.router.navigateByUrl(url)
  }

  userLogout(){
    if(this.isVisitor){
      this.router.navigateByUrl('app/dashboard/visitor/visitor-login');
    }

    else{
      this.router.navigateByUrl('user/login');
    }

  }

  adminLogout(){
  this.router.navigateByUrl('vertexadmin/login');
  localStorage.removeItem('office_q_uid');
  }

}
