import { ChangeDetectorRef, Component, OnInit, AfterViewInit } from '@angular/core';
import { CommonService } from '../_service/common.service';

@Component({
  selector: 'app-profile',
  templateUrl: './profile.component.html',
  styleUrls: ['./profile.component.scss'],
})
export class ProfileComponent implements OnInit, AfterViewInit {
  isAdmin: boolean = false; // Strongly typed
  allDatta =[]

  constructor(public common: CommonService, private cdr: ChangeDetectorRef) {
    // Get and parse data from localStorage
    const data = localStorage.getItem('office_q_uid');
    if (data) {
      const parsedData = JSON.parse(data);
      this.isAdmin = parsedData.IsAdmin; // Use nullish coalescing to prevent errors
      console.log(' this.isAdmin: ',  this.isAdmin);
    }
  }

  ngOnInit() {
    console.log('Profile Data:', this.common.UserProfile);
    this.allDatta = this.common.UserProfile;
  }

  ngAfterViewInit(): void {
    // Detect changes to avoid ExpressionChangedAfterItHasBeenCheckedError
    this.cdr.detectChanges();
  }
}
